<template>
  <div class="d-flex justify-content-center pt-5">
    <div class="paper-box d-flex justify-content-center flex-column py-5">
        <button @click="resetAllQuest" v-if="staffLevel >= 20">Reset Entire QuestLine</button>
      <img src="/img/paper-box/title.png" style="max-width: 200px;" class="mx-auto w-100 mb-4"/>
      <div class="paper-box-title mx-auto mb-4">{{ currentQuest?.title || 'Coming Soon' }}</div>
      <div v-if="currentQuest">

        <div class="paper-box-inner-bg w-100 p-4 mb-4" v-if="!currentQuestId && currentQuest">
          <p v-html="currentQuest.pretext" class="mb-0"></p>
        </div>
        <div class="paper-box-inner-bg w-100 p-4 mb-4" v-if="currentQuestId > 0">
          <p v-html="currentQuest.steps[currentStep].script" class="mb-0"></p>
        </div>

        <div class="paper-box-inner-bg p-4 d-flex flex-row justify-content-around mb-4" v-if="trackers.length > 0">
          <QuestRequirement v-for="track in trackers" :key="track.id" :tracker-id="track.id"/>
        </div>

        <div class="paper-box-inner-bg p-4 d-flex justify-content-around" v-if="!currentQuestId || ableToCompleteStep || currentStep === currentQuestLength">
          <div v-if="!currentQuestId">
            <button @click="startQuest" class="complete">Start Quest</button>
          </div>

          <div v-if="currentStep === currentQuestLength">
            <button @click="handIn" class="complete">Complete Quest</button>
          </div>
          <div v-if="ableToCompleteStep && currentStep !== currentQuestLength">
            <button @click="nextStep">Next step</button>
          </div>
        </div>
      </div>
      <div v-else class="paper-box-inner w-100 p-4">
        There are no available quests, please check back soon for our next story
      </div>

      <div class="paper-box-bg"></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import QuestRequirement from "@/components/quest/QuestRequirement";

export default {
  name: 'Quest',
  components: {QuestRequirement},
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      currentQuest: "config/quest",
      trackers: "user/questTracker",
      currentQuestId: "user/currentQuestId",
      staffLevel: "user/staffLevel",
      currentStep: "user/currentQuestStep",
    }),
    currentQuestLength() {
      return (this.currentQuest.steps.length - 1)
    },
    ableToCompleteStep() {
      let completed = true;

      if (!this.currentQuestId)
        return false;

      this.trackers.map(tracker => {
        const requirements = this.currentQuest.steps[this.currentStep].requirements.find(req => req.id === tracker.requirement);

        if (tracker.progress < requirements.amount)
          completed = false;
      })

      return completed;
    }
  },
  methods: {
    handIn() {
      this.$socket.emit("auth:quest finish")
    },
    startQuest() {
      this.$socket.emit('auth:start quest')
    },
    nextStep() {
      this.$socket.emit("auth:quest next step")
    },
    resetQuest() {
      this.$socket.emit('auth:reset quest')
    },
    resetAllQuest() {
      this.$socket.emit("auth:reset all quest")
    }
  }
}
</script>