<template>
  <div class="d-inline-block">
    <div class="d-inline-block" v-if="requirement">
      <Item :quantity="amountNeeded < 0 ? 0 : amountNeeded" v-if="requirement.type === 'item'" :item="requirement.item"
            :invalid-amount="!hasAmountNeeded"/>
      <p v-if="hasAmountNeeded" class="text-success mb-0">Completed</p>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import Item from "@/components/items/Item";

export default {
  name: 'QuestRequirement',
  components: {Item},
  props: {
    trackerId: {
      type: Number
    }
  },
  computed: {
    amountNeeded() {
      return this.requirement.amount - this.tracker.progress;
    },
    hasAmountNeeded() {
      if (this.requirement.type === 'item') {
        return this.tracker.progress >= this.requirement.amount
      }

      return false;
    },
    requirement() {
      if (Object.keys(this.quest).length > 0)
      {
        if(!this.quest.steps[this.currentStep].requirements)
          return {};

        return this.quest.steps[this.currentStep].requirements.find(req => {
          return req.id === this.tracker.requirement;
        })
      }

      return {}
    },
    tracker() {
      return this.trackers.find(t => t.id === this.trackerId)
    },
    ...mapGetters({
      quest: "config/quest",
      trackers: "user/questTracker",
      currentStep: "user/currentQuestStep"
    })
  }
}
</script>